import { Box, Typography, Link, IconButton, Container, Button } from "@mui/material";
import { Facebook, Instagram, Linkedin, Mail, Phone, Youtube } from "lucide-react";
import { styled } from "@mui/system";

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: "#000",
  fontFamily: "Outfit",
  fontWeight: 400,
  fontSize: "14px",
}));

const FooterContainer = styled(Box)(({ theme }) => ({
  backgroundColor: "#fff",
  color: "#fff",
  padding: "2rem 0 5rem 0",
  display: "grid",
  gridTemplateColumns: "1fr",
  gap: "20px",
  textAlign: "left",
  [theme.breakpoints.up("sm")]: {
    gridTemplateColumns: "1fr 1fr",
  },
  [theme.breakpoints.up("md")]: {
    gridTemplateColumns: "1fr 1fr",
  },
  [theme.breakpoints.up("lg")]: {
    gridTemplateColumns: "repeat(4, 1fr)",
  },
}));

const Footer = () => {
  const contactInfo = [
    { title: "+91 9368000111", icon: Phone },
    { title: "viantaxdelhi@gmail.com", icon: Mail },
  ];

  const socialMedia = [
    { icon: Instagram, link: "#" },
    { icon: Facebook, link: "#" },
    { icon: Linkedin, link: "#" },
    { icon: Youtube, link: "#" },
  ];

  const footerLinks = [
    { title: "Home", path: "/" },
    { title: "About", path: "/about" },
    { title: "Privacy", path: "/" },
    { title: "T&C", path: "/" },
  ];

  return (
    <Box sx={{ backgroundColor: "#fff",  borderTop: "1px solid gray" }}>
      <Container>
        <FooterContainer>
          {/* Column 1: Company Info */}
          <Box>
            <Typography variant="h6" sx={{ fontWeight: "700", mb: 1 }}>
              Loan Rasta
            </Typography>
            {/* <Typography sx={{ fontSize: "14px", mb: 1 }}>CHARTERED ACCOUNTANTS</Typography> */}
            <Typography sx={{ fontSize: "14px", mb: 1 }}>
              #Plot No 406A, 2nd floor, CA Office Industrial Area,
            </Typography>
            <Typography sx={{ fontSize: "14px", mb: 1 }}>Phase –II, Chandigarh, 160002</Typography>
            <Link href="/contact">
              <Button sx={{ mt: 2 }} variant="contained" color="primary">
                Contact Us
              </Button>
            </Link>
          </Box>

          {/* Column 2: Contact Info */}
          <Box>
            <Typography variant="h6" sx={{ fontWeight: "700", mb: 1 }}>
              Contact Information
            </Typography>
            {contactInfo.map((item) => (
              <Box key={item.title} sx={{ display: "flex", justifyContent: "left", mb: 1 }}>
                <item.icon />
                <Typography sx={{ fontSize: "14px" }}>{item.title}</Typography>
              </Box>
            ))}
          </Box>

          {/* Column 3: Office Addresses */}
          <Box>
            <Typography variant="h6" sx={{ fontWeight: "700", mb: 1 }}>
              CHANDIGARH OFFICE
            </Typography>
            <Typography sx={{ fontSize: "14px", mb: 1 }}>
              Plot No 406A, 2nd floor, CA Office Industrial Area, Phase –II,
            </Typography>
            <Typography sx={{ fontSize: "14px", mb: 1 }}>Chandigarh -160002</Typography>
            <Typography sx={{ fontSize: "14px", mb: 1 }}>Email: viantaxdelhi@gmail.com</Typography>
          </Box>

          {/* Column 4: Social Media */}
          <Box>
            <Typography variant="h6" sx={{ fontWeight: "700", mb: 1 }}>
              Follow Us
            </Typography>
            <Box sx={{ display: "flex", gap: 2 }}>
              {socialMedia.map((social) => (
                <Link key={social.link} href={social.link} target="_blank">
                  <social.icon style={{ fontSize: 24, color: "#000" }} />
                </Link>
              ))}
            </Box>
          </Box>
        </FooterContainer>
      </Container>

      {/* Copyright Section */}
      <Box
        sx={{
          backgroundColor: "primary.main",
          color: "#fff",
          textAlign: "center",
          padding: "10px 0",
          
        }}
      >
        <Typography variant="body2" sx={{ color: "#fff" }}>
          © {new Date().getFullYear()} Loan Rasta. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
