// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './Layout';
import HomePage from './pages/Home/HomePage';
import AboutUsPage from './pages/AboutUs/AboutUsPage';
import ContactUsPage from './pages/ContactUs/ContactUsPage';
import MSMELoan from './pages/Loan/MSMELoan/MSMELoan';
import BusinessLoan from './pages/Loan/BusinessLoan/BusinessLoan';
import CCODLoan from './pages/Loan/CCODLoan/CCODLoan';
import HouseLoan from './pages/Loan/HouseLoan/HouseLoan';
import OverdraftLoan from './pages/Loan/OverdraftLoan/OverdraftLoan';
import PersonalLoan from './pages/Loan/PeronsalLoan/PersonalLoan';
import ProjectLoan from './pages/Loan/ProjectLoan/ProjectLoan';
import SubsidyLoan from './pages/Loan/SubsidyLoan/SubsidyLoan';
import BlogsPage from './pages/Blogs/BlogsPage';
import SingleBlog from './components/Blog/SingleBlog';
import ApplyNowForm from './components/ApplyNowForm/ApplyNowForm';

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<HomePage />} />
          <Route path="about" element={<AboutUsPage />} />
          <Route path="contact" element={<ContactUsPage />} />
          <Route path="applyNow" element={<ApplyNowForm />} />
          <Route path="blogs" element={<BlogsPage />} />
          <Route path="/blog/:id" element={<SingleBlog />} />
          <Route path="msmeloan" element={<MSMELoan />} />
          <Route path="businessloan" element={<BusinessLoan />} />
          <Route path="ccodloan" element={<CCODLoan />} />
          <Route path="houseloan" element={<HouseLoan />} />
          <Route path="overdraftloan" element={<OverdraftLoan />} />
          <Route path="personalloan" element={<PersonalLoan />} />
          <Route path="projectloan" element={<ProjectLoan />} />
          <Route path="subsidyloan" element={<SubsidyLoan />} />
        </Route>
      </Routes>
    </Router>
  );
};

export default App;
