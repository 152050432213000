import React from 'react';
import { Box, Typography, Link, useTheme } from '@mui/material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PhoneIcon from '@mui/icons-material/Phone';
import DownloadIcon from '@mui/icons-material/Download';
import EmailIcon from '@mui/icons-material/Email';

const StickyContactSection = () => {
  const theme = useTheme();

  const items = [
    {
      label: '+91 9368000111',
      link: 'https://wa.me/919368000111',
      icon: <WhatsAppIcon sx={{ color: theme.palette.primary.main }} />,
    },
    {
      label: '+91 9368000111',
      link: 'tel:+919368000111',
      icon: <PhoneIcon sx={{ color: theme.palette.primary.main }} />,
    },
    {
      label: 'Download Brochure',
      link: '/brochure.pdf',
      icon: <DownloadIcon sx={{ color: theme.palette.primary.main }} />,
    },
    {
      label: 'viantaxdelhi@gmail.com',
      link: 'mailto:viantaxdelhi@gmail.com',
      icon: <EmailIcon sx={{ color: theme.palette.primary.main }} />,
    },
  ];

  return (
    <Box
      sx={{
        position: 'fixed',
        right: '5px',
        top: '20%',
        transform: 'translateY(0)',
        display: {
          xs: 'none',
          md: 'block',
        },
        zIndex: 1000,
        padding: '16px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
        width: '230px',
      }}
    >
      {items.map((item, index) => (
        <Link
          key={index}
          href={item.link}
          target="_blank"
          underline="none"
          sx={{
            display: 'flex',
            alignItems: 'center',
            padding: '10px',
            marginBottom: '8px',
            backgroundColor: theme.palette.grey[100],
            borderRadius: '4px',
            textDecoration: 'none',
            color: theme.palette.text.primary,
            transition: 'background-color 0.3s ease',
            '&:hover': {
              backgroundColor: theme.palette.grey[200],
            },
          }}
        >
          {item.icon}
          <Typography
            sx={{ marginLeft: '12px', fontSize: '14px', fontWeight: 500 }}
          >
            {item.label}
          </Typography>
        </Link>
      ))}
    </Box>
  );
};

export default StickyContactSection;
