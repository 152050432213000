import { useState } from "react";
import { Box, TextField, Button, Typography, MenuItem, Modal, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ApplyNowForm = ({ open, handleClose }) => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    loanType: "",
    amount: "",
    address: "",
    income: "",
    employmentStatus: "",
    purpose: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted: ", formData);
    // Add form submission logic here
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          maxHeight: "80vh",
          overflowY: "auto",
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          position: "relative",
        }}
      >
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            top: 8,
            right: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant="h5" align="center" gutterBottom>
          Apply Now
        </Typography>
        <TextField
          label="Full Name"
          name="name"
          value={formData.name}
          onChange={handleChange}
          required
        />
        <TextField
          label="Email Address"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
        <TextField
          label="Phone Number"
          name="phone"
          type="tel"
          value={formData.phone}
          onChange={handleChange}
          required
        />
        <TextField
          label="Loan Type"
          name="loanType"
          select
          value={formData.loanType}
          onChange={handleChange}
          required
        >
          <MenuItem value="Personal Loan">Personal Loan</MenuItem>
          <MenuItem value="Business Loan">Business Loan</MenuItem>
          <MenuItem value="Home Loan">Home Loan</MenuItem>
          <MenuItem value="Car Loan">Car Loan</MenuItem>
        </TextField>
        <TextField
          label="Loan Amount"
          name="amount"
          type="number"
          value={formData.amount}
          onChange={handleChange}
          required
        />
        <TextField
          label="Address"
          name="address"
          value={formData.address}
          onChange={handleChange}
          required
        />
        <TextField
          label="Monthly Income"
          name="income"
          type="number"
          value={formData.income}
          onChange={handleChange}
          required
        />
        <TextField
          label="Employment Status"
          name="employmentStatus"
          select
          value={formData.employmentStatus}
          onChange={handleChange}
          required
        >
          <MenuItem value="Employed">Employed</MenuItem>
          <MenuItem value="Self-Employed">Self-Employed</MenuItem>
          <MenuItem value="Unemployed">Unemployed</MenuItem>
          <MenuItem value="Retired">Retired</MenuItem>
        </TextField>
        <TextField
          label="Purpose of Loan"
          name="purpose"
          value={formData.purpose}
          onChange={handleChange}
          required
        />
        <Button type="submit" variant="contained" color="primary">
          Submit Application
        </Button>
      </Box>
    </Modal>
  );
};

export default ApplyNowForm;
